import { CloseIcon } from '@dataplace.ai/ui-components/atoms'
import { ReactComponent as WarningIcon } from 'libs/shared/assets/src/lib/icons/warrningIcon.svg'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div(({
  theme: {
    palette,
    corners,
  },
}) => css`
  border-radius: ${corners.default.borderRadius};
  background-color: ${palette.light.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 2.5rem 1.5rem;
  width: 460px;

  form {
    margin: 0;
  }
`)

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
`

export const Title = styled.div(({
  theme: {
    palette,
    typography,
  },
}) => css`
    color: ${palette.black};
    font-size: ${typography.big.pt_28_semibold.fontSize};
    font-weight: ${typography.big.pt_28_semibold.fontWeight};
    line-height: ${typography.big.pt_28_semibold.lineHeight};
`)

export const Trigger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Description = styled.p(({
  theme: {
    palette,
    typography,
  },
}) => css`
  color: ${palette.black};
  margin-top: '0.5rem';
  font-size: ${typography.main.pt_15_regular.fontSize};
  font-weight: ${typography.main.pt_15_regular.fontWeight};
  line-height: ${typography.main.pt_15_regular.lineHeight};
`)

export const PoisInfoWrapper = styled.div`
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 2.5rem;
  right: 1.5rem;
`

export const PoisInfo = styled.h3(({ theme: { palette } }) => css`
  color: ${palette.results.red.dark};
  font-weight: 400;
  margin-top: 2rem;
`)

export const StyledWarningIcon = styled(WarningIcon)(({ theme: { palette } }) => css`
  width: 50px;
  height: 50px;
  path {
    stroke: ${palette.results.red.dark};
  }
`)
