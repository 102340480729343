export type McdRadarLabels = {key: string, value: string}[];

export const radarChartLabels: McdRadarLabels = [
  {
    key: 'above60Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.above_60_buff_1000',
  },
  {
    key: 'above60Buff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.above_60_buff_250',
  },
  {
    key: 'adults1939Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.adults_1939_buff_1000',
  },
  {
    key: 'adults1939Buff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.adults_1939_buff_250',
  },
  {
    key: 'adults4059Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.adults_4059_buff_1000',
  },
  {
    key: 'adults4059Buff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.adults_4059_buff_250',
  },
  {
    key: 'buildingsBuff25000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.buildings_buff_25000',
  },
  {
    key: 'ctdAvgDailyBuff50BurgerKing',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_avg_daily_buff50_Burger_King',
  },
  {
    key: 'ctdAvgDailyBuff50Kfc',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_avg_daily_buff50_KFC',
  },
  {
    key: 'ctdAvgDailyBuff50MaxBurger',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_avg_daily_buff50_Max_Burger',
  },
  {
    key: 'ctdAvgDailyBuff50PizzaHut',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_avg_daily_buff50_Pizza_Hut',
  },
  {
    key: 'ctdAvgDailyBuff50Subway',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_avg_daily_buff50_Subway',
  },
  {
    key: 'ctdSumMonthlyBuff100',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_sum_monthly_buff100',
  },
  {
    key: 'ctdSumMonthlyBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_sum_monthly_buff250',
  },
  {
    key: 'ctdSumMonthlyBuff50Kfc',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_sum_monthly_buff50_KFC',
  },
  {
    key: 'ctdSumMonthlyRoads',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_sum_monthly_roads',
  },
  {
    key: 'distanceBurgerKing',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_Burger_King',
  },
  {
    key: 'distanceKfc',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_KFC',
  },
  {
    key: 'distanceMaxBurger',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_Max_Burger',
  },
  {
    key: 'distancePizzaHut',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_Pizza_Hut',
  },
  {
    key: 'distanceSubway',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_Subway',
  },
  {
    key: 'distanceClosest1McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_closest1_mcd_type',
  },
  {
    key: 'distanceClosest2McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_closest2_mcd_type',
  },
  {
    key: 'distanceClosest3McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_closest3_mcd_type',
  },
  {
    key: 'distanceClosest4McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_closest4_mcd_type',
  },
  {
    key: 'driveThruPresence',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.drive_thru_presence',
  },
  {
    key: 'industryBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.industry_buff_1000',
  },
  {
    key: 'industryBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.industry_buff_250',
  },
  {
    key: 'industryBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.industry_buff_50',
  },
  {
    key: 'insidePlayland',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.inside_playland',
  },
  {
    key: 'mccafeCounter',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.mccafe_counter',
  },
  {
    key: 'medicalBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.medical_buff_1000',
  },
  {
    key: 'medicalBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.medical_buff_250',
  },
  {
    key: 'medicalBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.medical_buff_50',
  },
  {
    key: 'officesBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.offices_buff_1000',
  },
  {
    key: 'officesBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.offices_buff_250',
  },
  {
    key: 'officesBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.offices_buff_50',
  },
  {
    key: 'otherBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.other_buff_1000',
  },
  {
    key: 'otherBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.other_buff_250',
  },
  {
    key: 'otherBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.other_buff_50',
  },
  {
    key: 'outsidePlayland',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.outside_playland',
  },
  {
    key: 'popBuff25000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pop_buff_25000',
  },
  {
    key: 'ppQ1Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pp_q1_buff_1000',
  },
  {
    key: 'ppQ2Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pp_q2_buff_1000',
  },
  {
    key: 'ppQ3Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pp_q3_buff_1000',
  },
  {
    key: 'ppQ4Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pp_q4_buff_1000',
  },
  {
    key: 'ppQ5Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pp_q5_buff_1000',
  },
  {
    key: 'pylon',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pylon',
  },
  {
    key: 'religionBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.religion_buff_1000',
  },
  {
    key: 'religionBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.religion_buff_250',
  },
  {
    key: 'religionBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.religion_buff_50',
  },
  {
    key: 'residentialBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.residential_buff_1000',
  },
  {
    key: 'residentialBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.residential_buff_250',
  },
  {
    key: 'residentialBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.residential_buff_50',
  },
  {
    key: 'scienceCultureArtBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.science_culture_art_buff_1000',
  },
  {
    key: 'scienceCultureArtBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.science_culture_art_buff_250',
  },
  {
    key: 'scienceCultureArtBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.science_culture_art_buff_50',
  },
  {
    key: 'servicesBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.services_buff_1000',
  },
  {
    key: 'servicesBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.services_buff_250',
  },
  {
    key: 'servicesBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.services_buff_50',
  },
  {
    key: 'sportBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.sport_buff_1000',
  },
  {
    key: 'sportBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.sport_buff_250',
  },
  {
    key: 'sportBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.sport_buff_50',
  },
  {
    key: 'storageBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.storage_buff_1000',
  },
  {
    key: 'storageBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.storage_buff_250',
  },
  {
    key: 'storageBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.storage_buff_50',
  },
  {
    key: 'tourismBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.tourism_buff_1000',
  },
  {
    key: 'tourismBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.tourism_buff_250',
  },
  {
    key: 'tourismBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.tourism_buff_50',
  },
  {
    key: 'trafficClosest1McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.traffic_closest1_mcd_type',
  },
  {
    key: 'trafficClosest2McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.traffic_closest2_mcd_type',
  },
  {
    key: 'trafficClosest3McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.traffic_closest3_mcd_type',
  },
  {
    key: 'trafficClosest4McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.traffic_closest4_mcd_type',
  },
  {
    key: 'transportBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.transport_buff_1000',
  },
  {
    key: 'transportBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.transport_buff_250',
  },
  {
    key: 'transportBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.transport_buff_50',
  },
  {
    key: 'under18Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.under_18_buff_1000',
  },
  {
    key: 'under18Buff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.under_18_buff_250',
  },
  /// nowe
  {
    key: 'scoreSqrt',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.score_sqrt',
  },
  {
    key: 'total0018',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.total0018',
  },
  {
    key: 'dailyMeanCtdDonut1Km50M',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.daily_mean_ctd_donut_1km_50m',
  },
  {
    key: 'combinedScore',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.combined_score',
  },
  {
    key: 'tourismIndex',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.tourism_index',
  },
  {
    key: 'totalDensityVoronoi',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.total_density_voronoi',
  },
  {
    key: 'varCoefBuff1Km',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.var_coef_buff_1km',
  },
  {
    key: 'ffDistanceAdjScore',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ff_distance_adj_score',
  },
  {
    key: 'poiCount1Km',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.poi_count_1km',
  },
  {
    key: 'traffic1KmToTrafficPow',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.traffic_1km_to_traffic_pow',
  },
  {
    key: 'restaurants500M',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.restaurants_500m',
  },
  {
    key: 'meanIncome1000M',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.mean_income_1000m',
  },
  {
    key: 'builtPctg1Km',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.built_pctg_1km',
  },
  {
    key: 'buildingFurnitureShops1Km',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.building_furniture_shops_1km',
  },
  {
    key: 'distanceNearestRoad',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_nearest_road',
  },
  {
    key: 'residBuiltPctg1Km',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.resid_built_pctg_1km',
  },
  {
    key: 'meanIncome1000MToPow',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.mean_income_1000m_to_pow',
  },
  {
    key: 'share0018',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.share_0018',
  },
  {
    key: 'uniqueUsers',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.unique_users',
  },
  {
    key: 'trafficNearestRoad',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.traffic_nearest_road',
  },
  {
    key: 'voronoiPowPopulation',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.voronoi_pow_population',
  },
  {
    key: 'officeBuildingCount1Km',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.office_building_count_1km',
  },
  {
    key: 'nearestRoadType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.nearest_road_type',
  },
  {
    key: 'demographicAccessibilityGroup',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.demographic_accessibility_group',
  },
  {
    key: 'demographicStructureGroup',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.demographic_structure_group',
  },
  {
    key: 'trafficVolumeWithin1KmGroup',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.traffic_volume_within_1km_group',
  },
  {
    key: 'fastFoodSegmentPotentialGroup',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.fast_food_segment_potential_group',
  },
  {
    key: 'touristAttractivenessGroup',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.tourist_attractiveness_group',
  },
  {
    key: 'populationDensityGroup',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.population_density_group',
  },
  {
    key: 'localTrafficCharacteristicsGroup',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.local_traffic_characteristics_group',
  },
  {
    key: 'poiCountGroup',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.poi_count_group',
  },
  {
    key: 'residentIncomeGroup',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.resident_income_group',
  },
  {
    key: 'localBuildingCharacteristicsGroup',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.local_building_characteristics_group',
  },
  {
    key: 'transportAccessibilityGroup',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.transport_accessibility_group',
  },
  {
    key: 'notPresentMccafeCounter',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.not_present_mccafe_counter',
  },
  {
    key: 'withoutDriveThru',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.without_drive_thru',
  },
  {
    key: 'plannedInsidePlayland',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.planned_inside_playland',
  },
  {
    key: 'plannedPylon',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.planned_pylon',
  },
]
