
import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { IMapLocationProps, LinkWithIconCheckOnMap, MapTile, TileFormWrapper, TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { WMSLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { TileSectionIds } from '@dataplace.ai/constants'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'
import { RootState } from 'apps/placeme/src/redux/store'
import { ILandUsePlan } from './@types/ILandUsePlan'
import { legend } from './data'

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
  justify-content: flex-end;
  margin: 0.5rem 0;
`

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow-y: auto;
  height: 70vh;
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const LegendColor = styled.span<{ color: string }>(({ color }) => css`
  background-color: ${color};
  min-width: 22px;
  height: 12px;
`)

export const MultiLegendColor = styled.span<{ color1: string; color2: string }>(({
  color1, color2,
}) => css`
  position: relative;
  display: inline-block;
  min-width: 22px;
  height: 12px;
  background-color: ${color1};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 33%;
    width: 33%;
    height: 100%;
    background-color: ${color2};
    transform: skewX(-30deg);
  }
`)

const LegendDesc = styled.p`
  font-size: 9px;
`

export const LandUsePlanTile: React.FC<{data:ILandUsePlan, tileId: string}> = ({
  data, tileId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { value } = useSelector((state: RootState) => state.location)
  const { values } = useSelector((state: RootState) => state.analysis)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }
  const tile = useMemo(() => getTileInfo(tileId, TileSectionIds.VISUALIZATION, values), [values, tileId])

  const layers = useMemo<WMSLayers[]>(() => {
    if (!data?.value?.data) return []

    return [
      {
        id: tile?.id || '',
        layer: {
          data: {
            layers: data.value.data.layers,
            url: data.value.data.url,
            format: data.value.data.format,
            transparent: data.value.data.transparent,
          },
          options: {
            type: data.value.options.type,
            id: data.value.options.id,
          },
        },
      },
    ]
  }, [data, tileId, values])

  useEffect(() => {
    if (data?.value?.data?.url && mapLocation) {
      dispatch(saveTileData(TileSectionIds.VISUALIZATION, tileId, {
        ...data,
        mapLocation,
      }))
    }
  }, [data, dispatch, mapLocation, tileId])

  return !data || data?.loading
    ? (<Loader />)
    : (data?.value
      ? (
        <TileFormWrapper>
          <StyledTitleFormSectionSubTitle>
            <LinkWithIconCheckOnMap onClick={handleMapOpen} />
          </StyledTitleFormSectionSubTitle>
          <MapTile
            dragging
            height='500px'
            layers={layers}
            location={value}
            mapId='example-map-data-tile'
            pinDisplayed
            popupHeading={`${t('generic.chosen_location')}:`}
            popupParagraph={value?.address}
            setMapLocation={setMapLocation}
            showScaleControl
            width='100%'
            zoom={18}
            zoomControl
          />
          {isMapDisplayed
            ? (
              <MapVisualizationModal
                isDisplayed={isMapDisplayed}
                layers={layers}
                legend={(
                  <LegendWrapper>
                    {legend?.map((item) => (
                      <LegendItem key={item.label}>
                        {item.additionalColor
                          ? (
                            <MultiLegendColor
                              color1={item.additionalColor}
                              color2={item.color}
                            />
                          )
                          : (
                            <LegendColor color={item.color} />
                          )}
                        <LegendDesc>{t(item?.label)}</LegendDesc>
                      </LegendItem>
                    ))}
                  </LegendWrapper>
                )}
                location={value}
                mapId={`visualization-tile-${values?.find(c => c.id === 'visualization')?.tiles?.find(t => t.id === 'satellite')?.chosenRange?.catchmentId}`}
                setIsDisplay={setIsMapDisplayed}
                zoom={18}
              />
            )
            : null}
        </TileFormWrapper>
      )
      : (
        <TileFormWrapper
          style={{
            alignItems: 'center',
          }}
        >
          {t('status.info.generic.something_went_wrong')}
        </TileFormWrapper>
      )
    )
}

export default LandUsePlanTile
