export const legend = [
  {
    color: '#dba237',
    label: 'placeme.land_use_legend.single_family_housing',
  },
  {
    color: '#9e6937',
    label: 'placeme.land_use_legend.multi_family_housing',
  },
  {
    color: '#f46442',
    label: 'placeme.land_use_legend.service_building',
  },
  {
    additionalColor: '#f46442',
    color: '#a2d273',
    label: 'placeme.land_use_legend.sports_recreation',
  },
  {
    additionalColor: '#f46442',
    color: '#919599',
    label: 'placeme.land_use_legend.large_commercial_area',
  },
  {
    color: '#fef200',
    label: 'placeme.land_use_legend.agricultural_land',
  },
  {
    additionalColor: '#fef200',
    color: '#f46442',
    label: 'placeme.land_use_legend.production_service_areas',
  },
  {
    additionalColor: '#fef200',
    color: '#dba237',
    label: 'placeme.land_use_legend.farm_building_areas',
  },
  {
    color: '#9269ae',
    label: 'placeme.land_use_legend.production_storage_areas',
  },
  {
    color: '#9269ae',
    label: 'placeme.land_use_legend.mining_areas',
  },
  {
    color: '#68c28d',
    label: 'placeme.land_use_legend.nature_protection_areas',
  },
  {
    color: '#68c28d',
    label: 'placeme.land_use_legend.forests',
  },
  {
    color: '#a1d172',
    label: 'placeme.land_use_legend.urban_green_areas',
  },
  {
    color: '#a1d172',
    label: 'placeme.land_use_legend.garden_areas',
  },
  {
    color: '#a1d172',
    label: 'placeme.land_use_legend.cemeteries',
  },
  {
    additionalColor: '#a1d172',
    color: '#ffffff',
    label: 'placeme.land_use_legend.flood_prone_areas',
  },
  {
    color: '#00baf0',
    label: 'placeme.land_use_legend.sea_water_areas',
  },
  {
    color: '#00baf0',
    label: 'placeme.land_use_legend.inland_water_areas',
  },
  {
    color: '#f8f9fa',
    label: 'placeme.land_use_legend.public_roads',
  },
  {
    color: '#d7d7d7',
    label: 'placeme.land_use_legend.internal_roads',
  },
  {
    color: '#5585c4',
    label: 'placeme.land_use_legend.waterways',
  },
  {
    color: '#909498',
    label: 'placeme.land_use_legend.technical_infrastructure',
  },
]
