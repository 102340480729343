import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/database'
import { firebaseConfig } from './firebaseConfig'

const config = {
  apiKey: firebaseConfig.API_KEY,
  authDomain: `${firebaseConfig.PROJECT_ID}.firebaseapp.com`,
  projectId: `${firebaseConfig.PROJECT_ID}`,
  storageBucket: `${firebaseConfig.PROJECT_ID}.appspot.com`,
  appId: firebaseConfig.PROJECT_ID,
  databaseURL: firebaseConfig.DATABASE_URL,
}

export const initFirebase = ():firebase.app.App => {
  const firebaseService = firebase.initializeApp(config)

  return firebaseService
}

export const firebaseService = initFirebase()
export const firebaseDatabase = firebase.database()
export const firebaseEastRegion = process.env.NX_FIREBASE_ENVIRONMENT === 'LOCAL' ? undefined : 'europe-central2'
