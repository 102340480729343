import React, { useContext, useEffect, useMemo, useState } from 'react'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { AuthContext } from '@dataplace.ai/features'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { getAnwRadarChartData, getRadarChartLabels, Loader, MixedChart, RadarChart } from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { useFetchTileData } from 'apps/placeme/src/customHooks/useFetchTileData'
import { DemoInfoSection } from '@dataplace.ai/ui-components/molecules/DemoInfoSection/DemoInfoSection'
import { useTranslation } from 'react-i18next'
import { deleteTileAction, saveNewRangeAction } from '../../../../../slice/analysisSlice'
import { McdPotentialModelForm } from './McdPotentialModelForm'
import { DividerBox, Wrapper } from './McdPotentialModelTile.styles'
import { Settings, SettingsListElement } from '../Settings'
import { FormFieldValue, IMcdPotentialTileData, InitialFormState } from './McdPotentialModel'
import { FormDataObject } from './McdPotentialModelData/hooks/formData/useFormFieldData'
import { BarData } from './DemoSalesPredictionModelData/hooks/resultData/useGetChartData'
import { FormDataObjectDemo } from './DemoSalesPredictionModelData/hooks/formData/useFormFieldData'
import { McdRadarLabels } from './McdPotentialModelData/hooks/resultData/radarChartLabels'

const Section = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: ${palette.light.white};
    border: 1px solid ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    color: ${palette.black};
    margin-bottom: 30px;
  `
})

const StyledH4 = styled.h4(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.main.pt_15_medium_upper.fontSize};
    font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
    line-height: ${typography.main.pt_15_medium_upper.lineHeight};
    letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
    text-transform: ${typography.main.pt_15_medium_upper.textTransform};
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
})

const StyledParagraph = styled.p(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
    margin-bottom: 15px;
  `
})

const StyledSpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.big.pt_28_semibold.fontSize};
    font-weight: ${typography.big.pt_28_semibold.fontWeight};
    line-height: ${typography.big.pt_28_semibold.lineHeight};
    font-family: 'Inter',sans-serif;
    display: inline;
    margin-left: 0.25rem;
  `
})

export const McdPotentialTile: React.FC<{
  data: IMcdPotentialTileData,
  tileId: string,
  categoryId: string,
  body: unknown,
  barData: BarData
  formDataObject: FormDataObject | FormDataObjectDemo
  formState: InitialFormState,
  settingList: SettingsListElement[],
  endpoint: string,
  labels: McdRadarLabels,
  setLocationAdditions: React.Dispatch<React.SetStateAction<string[]>>,
  setFormValue: (key: string, value: FormFieldValue) => void,
}> = ({
  data,
  tileId,
  categoryId,
  formState,
  settingList,
  setFormValue,
  setLocationAdditions,
  body,
  barData,
  formDataObject,
  endpoint,
  labels,
}) => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const dispatch = useAppDispatch()
  const { analyseId } = useSelector((state: RootState) => state.location)
  const { values } = useSelector((state: RootState) => state.analysis)
  /// USESTATE /////////////////////
  const [canBeRequest, setCanBeRequest] = useState(false)
  const [token, setToken] = useState('')

  const { fetchData } = useFetchTileData(
    data,
    endpoint,
    tileId,
    categoryId,
    body,
    setCanBeRequest,
  )

  const tile = useMemo(() =>
    values?.find(category => category.id === categoryId)?.tiles.find(tile => tile.id === tileId),
  [values])

  const handleSave = () => {
    setCanBeRequest(true)
    fetchData()
  }

  const handleDeleteTile = () => {
    dispatch(deleteTileAction(token, categoryId, tileId, analyseId || ''))
  }

  /// USEEFFECT /////////////////
  useEffect(() => {
    authContext?.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  // generates catchment
  useEffect(() => {
    if (!tile?.data?.value && token && formState.locationType) {
      if (!tile?.chosenRange?.catchmentId) {
        dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', categoryId, tileId, {
          id: `${tileId}-250-line`,
          value: 250,
          type: 'line',
        }))
      }
    }
  }, [token, canBeRequest])

  useEffect(() => {
    if (!data?.value && canBeRequest) {
      handleSave()
    }
  }, [!data?.value, tile?.chosenRange?.catchmentId, canBeRequest])

  const memoizedData = useMemo(() =>
    getAnwRadarChartData(data?.value?.potentialModel?.influence),
  [getAnwRadarChartData, data?.value?.potentialModel?.influence])

  const memoizedLabels = useMemo(() =>
    getRadarChartLabels(data?.value?.potentialModel?.influence, labels),
  [data?.value?.potentialModel?.influence, labels])

  if (canBeRequest && (data?.loading || !data?.value)) return <Loader />

  return (
    (!canBeRequest) && !data?.value
      ? (
        <McdPotentialModelForm
          categoryId={categoryId}
          formDataObject={formDataObject}
          formState={formState}
          handleDeleteTile={handleDeleteTile}
          handleSubmit={handleSave}
          setFormValue={setFormValue}
          setLocationAdditions={setLocationAdditions}
        />
      )
      : (
        <Wrapper background='light'>
          <Settings selectedSettings={settingList} />
          <DemoInfoSection categoryId={categoryId} />
          <Section>
            <StyledH4>{barData.sales.title}</StyledH4>
            <StyledParagraph>
              {barData.sales.paragraph}
              {': '}
              <StyledSpan>{`${formatNumber(barData.sales.datasets.bar.value)} PLN`}</StyledSpan>
            </StyledParagraph>
          </Section>
          <MixedChart
            currency='PLN'
            dataset={barData.sales.datasets}
            label={barData.sales.datasets.bar.label1}
          />
          <DividerBox />
          <Section>
            <StyledH4>{barData.transactions.title}</StyledH4>
            <StyledParagraph>
              {barData.transactions.paragraph}
              {': '}
              <StyledSpan>{`${formatNumber(barData.transactions.datasets.bar.value)}`}</StyledSpan>
            </StyledParagraph>
          </Section>
          <MixedChart
            dataset={barData.transactions.datasets}
            label={barData.transactions.datasets.bar.label1}
          />
          {memoizedData && memoizedLabels
            ? (
              <>
                <Section>
                  <StyledH4>{t('placeme.mcd_potential_tile.radar.title')}</StyledH4>
                </Section>
                <RadarChart
                  data={memoizedData}
                  height={650}
                  labels={memoizedLabels}
                  maxScale={10}
                  minScale={data.value.settings.locationType === 'freestander' ? 0 : -10}
                />
              </>
            )
            : null}
        </Wrapper>
      )
  )
}
