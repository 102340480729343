interface IConfig {
  PROJECT_ID: string;
  API_KEY: string;
  AUTH_SESSION_ENDPOINT: string;
  DATABASE_URL?: string
}

const generateEnvConfig = () => {
  const AUTH_SESSION_ENDPOINT = `${process.env.NX_PLACEME_API_URL}/v2/session`
  switch (process.env.NX_FIREBASE_ENVIRONMENT !== 'undefined' ? process.env.NX_FIREBASE_ENVIRONMENT : process.env.NX_ENV) {
    case 'LOCAL':
      return {
        PROJECT_ID: 'dataplace-app-testing', // actually not necessary
        API_KEY: 'AIzaSyDZDue8RrNwzO7leY0n1-VhEFZhbYwX8-8', // actually not necessary
        DATABASE_URL: 'https://dataplace-app-testing-default-rtdb.firebaseio.com',
        AUTH_SESSION_ENDPOINT,
      }
    case 'ALPHA':
      return {
        PROJECT_ID: 'dataplace-app-testing',
        API_KEY: 'AIzaSyDZDue8RrNwzO7leY0n1-VhEFZhbYwX8-8',
        DATABASE_URL: 'https://dataplace-app-testing-default-rtdb.firebaseio.com',
        AUTH_SESSION_ENDPOINT,
      }
    case 'BETA':
      return {
        PROJECT_ID: 'dataplace-app-testing',
        API_KEY: 'AIzaSyDZDue8RrNwzO7leY0n1-VhEFZhbYwX8-8',
        AUTH_SESSION_ENDPOINT,
      }
    case 'PROD':
      return {
        PROJECT_ID: 'dataplace-app',
        API_KEY: 'AIzaSyDE30CPTypV6U-Mfb_zj-m2oRoQZ_VNZaw',
        DATABASE_URL: 'https://dataplace-app-default-rtdb.europe-west1.firebasedatabase.app',
        AUTH_SESSION_ENDPOINT,
      }
    default: // beta
      return {
        PROJECT_ID: 'dataplace-app',
        API_KEY: 'AIzaSyDE30CPTypV6U-Mfb_zj-m2oRoQZ_VNZaw',
        AUTH_SESSION_ENDPOINT,
      }
      // return {
      //   PROJECT_ID: 'dataplace-app-testing',
      //   API_KEY: 'AIzaSyDZDue8RrNwzO7leY0n1-VhEFZhbYwX8-8',
      //   AUTH_SESSION_ENDPOINT: 'https://testing.session-management.dataplace.ai',
      // }
  }
}

export const firebaseConfig: IConfig = generateEnvConfig()
